// Variables
$enable-caret: false;

// Colors
$primary: #65b02f;
$secondary: #06632f;
$gray-100: #f9f9f9;
$gray-300: #e9e9e9;
$gray-900: #333333;
$light: $gray-100;
$light-alt: #f9fbfd;

// Ratio
$min-contrast-ratio: 2;

// Fonts
$font-family-sans-serif: "Montserrat", sans-serif;
$font-size-base: 0.875rem;

$h1-font-size: 1.675rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.375rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;

// Border
$border-radius: 0.25rem;

// Button
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1.5rem;
$btn-padding-x: 2.5rem;
$btn-padding-y-lg: 0.625rem;
$btn-padding-x-lg: 2rem;
$btn-font-weight: 500;
$btn-line-height: 1;

// Breadcrumb
$breadcrumb-margin-bottom: 0;
$breadcrumb-font-size: 13px;
$breadcrumb-item-padding-x: 0.625rem;

// Input
$form-label-font-weight: 700;

// Modal
$modal-content-border-radius: 1rem;

// Card
$card-border-radius: 0;
$card-img-overlay-padding: 0;
$card-border-color: $gray-100;
$card-title-spacer-y: 0.625rem;
