// Header data
.s-top {
  @include media-breakpoint-up(lg) {
    position: relative;
    z-index: 20;
  }
  .s-top__nav {
    .nav-item:first-child .nav-link {
      padding-left: 0;
    }
  }
  &__nav {
    .nav-link {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      color: $gray-900;
      &:hover {
        color: $primary;
      }
    }
  }

  &__callback {
    display: block;
    border-bottom: 1px dotted;
    padding: 0;
    line-height: 1;
    @include transition();
    &:hover {
      border-color: transparent;
    }
  }
}

// Catalog
.nav-catalog__btn {
  padding: 0.625rem 1.5rem;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
}

.nav-catalog {
  &__dropdown {
    list-style-type: none;
    margin: 0;
    padding: 2px 0;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    min-width: 224px;
    > li + li {
      border-top: 1px solid $gray-300;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 16px 32px 16px 12px;
    font-weight: 500;
    font-size: 14px;
    color: $black;
    line-height: 1;
    text-decoration: none;
    &:hover {
      background-color: $gray-100;
      font-weight: bold;
      color: $primary;
    }
  }
  &__arrow {
    margin-left: auto;
    margin-right: -15px;
    font-size: 14px;
  }
  &__link.d-lg-none {
    .icon {
      transform: rotate(-90deg);
    }
    &.collapsed .icon {
      transform: rotate(90deg);
    }
  }
}

.s-lang {
  display: flex;
  margin-left: 1rem;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;

  &__select + &__select {
    border-left: 1px solid #ddd;
  }

  &__select {
    padding: 0 0.375rem;
    color: $gray-900;
    text-decoration: none;

    &.active,
    &:hover {
      color: $gray-600;
    }
  }
}

// Nav dropdown
.nav-catalog .nav-dropdown {
  &__wrapper {
    background-color: #fff;
  }
  @include media-breakpoint-up(lg) {
    &__wrapper {
      border-left: 2px solid $primary;
      z-index: 18;
      position: absolute;
      top: 0;
      left: 115%;
      visibility: hidden;
      padding: 0.25rem;
    }
    &__wrapper.collapse {
      display: flex;
      opacity: 0;
      min-height: 100%;
      transition: opacity 0.4s, left 0.4s, right 0.4s, visibility 0.4s;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    &:hover > .nav-dropdown__wrapper {
      left: 100%;
      opacity: 1;
      visibility: visible;
    }

    &__inner {
      width: 260px;
    }

    &__title {
      font-size: 16px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__inner {
      width: 300px;
    }
  }

  &__inner {
    padding: 0 1rem;
  }

  &__child {
    color: $gray-900;
    text-decoration: none;
  }
}

// Header
.s-header {
  position: sticky;
  top: 0;
  z-index: 17;
  background-color: $white;
  &__icon {
    font-size: 20px;
    text-decoration: none;
    position: relative;
  }
  &__counter {
    display: block;
    position: absolute;
    right: calc(50% - 1.375rem);
    top: -0.5rem;
    background: $light-alt;
    border-radius: 1rem;
    min-width: 1rem;
    height: 1rem;
    font-weight: 600;
    font-size: 10px;
    color: $gray-900;
    line-height: 1rem;
    &.bg-primary {
      color: $white;
    }
  }

  @include media-breakpoint-down(lg) {
    &__icon {
      font-size: 24px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__icons {
      display: flex;
      min-width: 26%;
    }
    &__icon {
      flex-grow: 1;
    }
  }
  @include media-breakpoint-down(md) {
    &__icons {
      min-width: 30%;
    }
  }
  @include media-breakpoint-down(sm) {
    &__icons {
      margin-top: 0.5rem;
      min-width: 100%;
    }
  }

  .site-logo {
    max-width: 40%;
    padding: 0.25rem 0;

    @include media-breakpoint-up(lg) {
      max-width: 15%;
    }
  }

  &__info {
    .dropdown-menu {
      .dropdown-item-text {
        display: flex;
        &__sm {
          font-size: 13px;
        }
      }
      .icon {
        font-size: 16px;
        color: $primary;
        padding-top: 0.125rem;
        width: 20px;
        flex: 0 0 20px;
        text-align: cneter;
      }
      .s-telephone__icon {
        font-size: 20px;
      }
      .s-telephone__text {
        text-decoration: none;
        font-size: 16px;
      }
      a {
        text-decoration: none;
        &:hover {
          color: $gray-600;
        }
      }
    }
  }
}

.desktop-menu-wrapper {
  display: flex;
}
@include media-breakpoint-down(lg) {
  .desktop-menu-wrapper {
    flex-direction: column-reverse;
    .top-serach {
      margin-bottom: 1rem;
    }
    .nav-catalog {
      &__btn {
        display: none;
      }
      &__dropdown {
        display: block;
        position: static;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .offcanvas-body {
    overflow: initial;
  }
}

/* Header cart */
.modal-header-cart .table {
  border-bottom: 1px solid $light;
}

.modal-header-cart .table th {
  font-weight: 300;
  color: $gray-600;
  padding-bottom: 20px;
}

.modal-header-cart .table th.cart-name {
  padding-left: 25px;
}

.modal-header-cart .table td {
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: none;
  font-weight: 500;
}

.modal-header-cart .cart-image {
  width: 90px;
}

.modal-header-cart .cart-name a {
  font-size: 13px;
  color: $black;
  display: block;
  line-height: 1.2;
  text-decoration: none;
}

.modal-header-cart .cart-name a:hover {
  color: $gray-600;
}

.cart-options {
  margin-top: 0.25rem;
  font-size: 13px;
  font-weight: 400;
  color: $gray-600;
  line-height: 1;
}

.modal-header-cart .btn-quantity {
  display: block;
  background-color: $primary;
  color: $white;
  border-radius: 0;
}

.modal-header-cart .btn-quantity:hover,
.modal-header-cart .btn-quantity:focus,
.modal-header-cart .btn-quantity:active:focus {
  background-color: $gray-600;
  outline: none;
}

.modal-header-cart .cart-quantity .form-control {
  max-width: 60px;
}

.modal-header-cart .cart-price,
.modal-header-cart .cart-total {
  color: $primary;
  white-space: nowrap;
}

.modal-header-cart .cart-remove {
  vertical-align: middle;
}

.modal-header-cart .cart-remove .btn {
  padding: 0;
  display: block;
  font-size: 30px;
  line-height: 1;
  margin: auto;
  color: #e64040;
  text-decoration: none;
}

.modal-header-cart .cart-remove .btn:hover {
  text-decoration: none;
  color: #bb4b4b;
}

.cart-result-total {
  font-size: 17px;
  font-weight: 400;
}

.cart-result-total strong {
  font-size: 20px;
  margin-left: 12px;
}

@include media-breakpoint-up(lg) {
  .modal-header-cart .modal-footer {
    padding-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .modal-header-cart .modal-footer .btn {
    margin: 0 10px;
    min-width: 230px;
    font-size: 13px;
  }

  .modal-header-cart .modal-footer .btn[data-dismiss="modal"] {
    margin-right: auto;
  }
  .cart-result-total {
    font-size: 20px;
  }

  .cart-result-total strong {
    font-size: 24px;
  }
}

@include media-breakpoint-down(sm) {
  .modal-header-cart .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@include media-breakpoint-down(lg) {
  .modal-header-cart .table td {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
  }

  .modal-header-cart .table thead {
    display: none;
  }

  .modal-header-cart .table td.cart-image {
    width: 20%;
  }

  .modal-header-cart .table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .modal-header-cart .cart-name {
    display: block;
    width: 80%;
  }

  .modal-header-cart .cart-total {
    width: 30%;
    margin-left: auto;
  }

  .modal-header-cart .cart-quantity {
    width: 45%;
  }

  .modal-header-cart .table td.cart-remove {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 49px;
  }

  .cart-result-total .text-right {
    text-align: center;
  }

  .modal-header-cart .modal-footer .btn {
    display: block;
    width: 100%;
  }

  .modal-header-cart .modal-footer .btn + .btn {
    margin-left: 0;
    margin-top: 10px;
  }
}

.empty-cart {
  padding: 15px;
  margin: 0;
}

/* Search autocomplete */
.top-serach {
  z-index: 20;
  .dropdown-menu {
    width: 100%;
    > li + li {
      border-top: 1px solid $gray-300;
    }
    > li > a {
      display: block;
      padding: 0.25rem 0.75rem;
      text-decoration: none;
      &:hover {
        background-color: $gray-100;
      }
    }
  }
  .form-control {
    border-color: $gray-300;
    height: 36px;
    font-size: 15px;
    border-radius: 10px;
  }
  .btn {
    height: 36px;
    border-radius: 10px;
    padding: 2px 12px;
    font-size: 24px;
  }
}

.search-item {
  display: flex;
  align-items: center;

  &__content {
    display: block;
    padding-left: 10px;
    line-height: 1;
    white-space: normal;
  }
  &__name {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 13px;
    color: $body-color;
  }
  &__model {
    display: block;
    margin-bottom: 3px;
    font-size: 13px;
    color: $gray-900;
  }
  &__price {
    font-size: 13px;
    font-weight: bold;
    color: $primary;
  }
  &__priceOld {
    margin-left: 15px;
    font-weight: 500;
    font-size: 12px;
    color: $gray-600;
    text-decoration: line-through;
  }
}

// Telephone
.s-telephone {
  font-size: 15px;
  text-decoration: none;
  @include media-breakpoint-up(lg) {
    font-size: 17px;
  }
  &__text {
    font-weight: 700;
    color: $secondary;
  }
}
