body {
  min-width: 320px;
}

// Container
.container {
  &,
  &-xxl {
    .container,
    .container-xxl {
      padding: 0;
    }
  }
}
.container-sm {
  max-width: 1140px;
}

// Content row
#column-left {
  @include media-breakpoint-up(lg) {
    width: 20%;
  }

  ~ #content {
    flex: 1 0 0%;
  }
}

.mt-n1 {
  margin-top: -0.25rem;
}

// Modal
.modal-header {
  border-bottom: none;
  align-items: normal;
  padding: 24px 16px 12px;

  @include media-breakpoint-up(sm) {
    padding: 32px 40px 12px;
  }

  .btn-close {
    position: absolute;
    right: 16px;
    top: 16px;

    @include media-breakpoint-up(sm) {
      right: 24px;
      top: 24px;
    }
  }
}

.modal-title {
  width: 100%;
  padding-top: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
}

.modal-body {
  padding: 16px;

  @include media-breakpoint-up(sm) {
    padding: 20px 40px;
  }
}

.modal-footer {
  border-top: none;
  padding: 0 16px 20px;

  @include media-breakpoint-up(sm) {
    padding: 0 40px 32px;
  }
}

// Button
.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Dropdown
.dropdown-toggle::after {
  content: "\e994";
  font-family: elementskit !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.25rem;
}

// Background
.bg-light {
  background-color: $light-alt !important;
}

// Page
.s-page {
  &__title {
    margin: 0 0 0.25rem;
    font-weight: 800;
    font-size: 25px;
    line-height: 1;
  }
  &__desription {
    font-weight: 500;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-weight: 900;
    }
  }
}

.btn-load-more {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;

  .icon {
    margin-top: -2px;
    margin-bottom: -1px;
    margin-left: 8px;
    width: 18px;
    height: 18px;
    line-height: 20px;
    text-align: center;
  }

  &.animated .icon {
    -webkit-animation: 0.5s linear rotate infinite;
    animation: 0.5s linear rotate infinite;
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Breadcrumbs
.breadcrumb-item {
  display: flex;
  align-items: center;
  + .breadcrumb-item::before {
    display: none;
  }
  a {
    color: $gray-900;
    text-decoration: none;
    &:hover {
      color: $gray-600;
    }
  }
  .icon {
    margin-top: 1px;
    margin-right: 0.625rem;
    font-size: 14px;
  }
  span {
    font-weight: bold;
    color: $gray-900;
  }
}

// Sort
.s-sort {
  line-height: 1;
  &__btn {
    display: inline-flex;
    align-items: center;
    font-weight: bold;

    .icon {
      margin-left: 0.25rem;
      margin-bottom: -1px;
      font-size: 13px;
      color: $primary;
    }

    &.show,
    &.active,
    &:active {
      border-color: transparent !important;
    }
  }

  .dropdown-menu {
    .btn {
      display: block;
      width: 100%;
      padding: 0.375rem 1rem;
      border-radius: 0;
      font-size: 13px;
      white-space: nowrap;
      text-align: left;
      &:not(.active):hover {
        color: $gray-600;
      }
      &.active {
        border-color: transparent;
        background-color: $light-alt;
      }
    }
  }
}

// Form
#product {
  .form-check {
    padding-left: 1.25em;
    min-height: 1.125rem;
  }

  .form-check-centered {
    position: relative;

    .form-check-input {
      position: absolute;
      top: 50%;
      margin-top: -6px;
      margin-left: 0;
      left: 0;
    }
  }

  .form-check-label {
    display: flex;
    align-items: center;
    padding-top: 0.125rem;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;

    span {
      font-weight: bold;
    }
  }

  .form-check-input {
    border: 2px solid $primary;
    width: 12px;
    height: 12px;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 2px;
    background-size: 12px;
  }
}

// Cards
.card-date {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 13px;
  color: $gray-900;
  line-height: 13px;

  .icon {
    width: 1.25em;
    margin-right: 0.25rem;
    font-size: 20px;
    color: $primary;
  }
}
.card-text {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  color: $gray-900;
}

#toast-container > div {
  opacity: 1 !important;
}

.category-list {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    li {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      margin-bottom: 1rem;
      width: 50%;
      text-align: center;
      @include media-breakpoint-up(sm) {
        width: 33.3333%;
      }
      @include media-breakpoint-up(md) {
        width: 25%;
      }
      @include media-breakpoint-up(lg) {
        width: 16.6666%;
      }

      img {
        margin-bottom: 0.5rem;
        max-width: 100%;
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
      span {
        font-weight: 600;
        margin-bottom: 0.5rem;
        display: block;
      }
    }
  }
}
