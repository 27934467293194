// General
.module-title {
  &::after {
    display: block;
    content: "";
    border-bottom: 2px dotted $cyan-500;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    max-width: 322px;
  }
}

// Slideshow
.slideshow-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

// Advantages
.advantages {
  &__num {
    width: 1.25em;
    margin-right: 12px;
    font-weight: 700;
    font-size: 35px;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    stroke-width: 1px;
    -webkit-text-stroke-color: $black;
    stroke: $black;
  }

  &__text {
    margin-top: 5px;
  }
}

// News
.s-page__articles {
  .s-categories__title::before {
    background-image: linear-gradient(90deg, $primary 55%, transparent 80%);
  }
}

.s-article {
  &__description {
    font-weight: 500;
    font-size: 14px;
    color: $gray-900;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 900;
      line-height: 1;
    }

    img {
      clear: both;
      max-width: 100%;
      height: auto !important;
      display: block;
      margin: 12px auto;
    }
  }
}

// Contact page
.s-contact {
  &__item {
    display: flex;
    margin-bottom: 1rem;
    padding-top: 0.375rem;
    font-weight: 500;
    font-size: 14px;
    color: $gray-900;
    line-height: 1.2;
  }

  &__icon {
    display: block;
    background-color: $primary;
    margin-top: -0.375rem;
    margin-right: 0.75rem;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    border-radius: 0.25rem;
    font-size: 20px;
    color: $white;
    text-align: center;
    line-height: 32px !important;
  }

  &__link {
    color: $gray-900;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}

.s-delivery-payment {
  .col {
    margin-bottom: 1.5rem;
  }
  &__title {
    margin-bottom: 1rem;
    font-weight: 800;
    font-size: 20px;
  }
  ul {
    margin: 0;
    padding: 0 0 0 12px;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 1.2;
      + li {
        margin-top: 16px;
      }
    }
  }

  .icon {
    font-size: 36px;
    color: $primary;
    flex: 0 0 1.25em;
    width: 1.25em;
    margin-right: 0.5rem;
  }
}
