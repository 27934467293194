.s-footer {
  padding: 3rem 0;
  background-color: $light-alt;

  &__text {
    margin-top: 1.5rem;
    font-size: 12px;
    line-height: 1.2;
  }

  ul {
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  li + li {
    margin-top: 12px;
  }

  a {
    font-size: 14px;
    color: $gray-900;
    text-decoration: none;

    &:hover {
      color: $gray-600;
    }
  }

  &__contacts {
    &,
    a {
      color: $secondary;
      font-size: 15px;
    }

    li {
      display: flex;
      align-items: center;
      & + li {
        margin-top: 8px;
      }
    }

    .icon {
      width: 1.25em;
      margin-right: 5px;
      font-size: 20px;
      color: $primary;
    }
  }

  &__telephone {
    font-weight: 800;
    a {
      font-size: 20px;
      color: $secondary;
    }
  }
}

.to-top-button {
  position: fixed;
  z-index: 99;
  right: 20px;
  bottom: -100%;
  visibility: hidden;
  opacity: 0;
  transition: color 0.15s ease-in-out, bottom 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
}

.to-top-button.show {
  visibility: visible;
  opacity: 1;
  bottom: 20px;
}
