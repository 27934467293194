// Category card
.s-categories {
  &__overlay {
    display: flex;
    align-items: end;
    background-color: rgba($black, 0.4);
    overflow: hidden;
  }
  &__link {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: $white;
    text-decoration: none;
    line-height: 1.2;
  }
  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.625rem;
    z-index: 1;
    position: relative;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: transparent;
      background-image: linear-gradient(90deg, $primary 33%, transparent 65%);
      z-index: -1;
      transition: right 0.3s;
    }
  }
  .card:hover &__title::before {
    right: -300%;
  }
  &__icon {
    margin-left: auto;
    margin-right: 1rem;
    padding-left: 1rem;
    font-size: 25px;
  }
}

// Product card
.s-products {
  .swiper-slide.swiper-slide-active ~ .swiper-slide .card {
    margin-left: -1px;
  }
  &__title {
    display: block;
    min-height: 26px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
  &__stock {
    font-weight: 500;
    font-size: 12px;
    color: $cyan;
  }
  &__model {
    font-size: 12px;
  }
  &__price {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    font-weight: 700;
    font-size: 17px;
    line-height: 1;
  }
  &__special {
    color: $primary;
  }
  &__old {
    margin-left: 1rem;
    font-weight: 300;
    font-size: 15px;
    color: $gray-600;
    text-decoration: line-through;
  }
  &__noprice {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    min-height: 17px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  &__btn {
    font-size: 19px;
  }
  .hstack .btn {
    height: 2.25rem;
  }

  @include media-breakpoint-down(sm) {
    .btn-primary {
      font-size: 22px;
    }
    &__price {
      flex-wrap: wrap;
      font-size: 15px;
      min-height: 27px;
    }
    &__old {
      width: 100%;
      font-size: 12px;
    }
    &__noprice {
      min-height: 27px;
      line-height: 13px;
    }
  }
}

.card-out .s-products {
  &__stock {
    color: $red;
  }
}

// Category module
.aside-categories {
  margin-bottom: 1rem;
  &__title {
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
  }
  &__children {
    padding-left: 0.625rem;
    overflow: hidden;
  }
  &__link {
    display: block;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-weight: 500;
    font-size: 13px;
    color: $gray-900;
    line-height: 1;
    text-decoration: none;
    &:hover,
    &.active {
      color: $primary;
    }
  }
  &__all {
    display: inline-block;
    cursor: pointer;
    margin-left: 0.625rem;
    margin-top: 0.25rem;
    border-bottom: 1px dotted;
    color: $primary;
    line-height: 1.2;
    &:hover {
      border-color: transparent;
    }
  }
}
