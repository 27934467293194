.s-thumbnail {
  &__thumbs {
    width: 80%;
  }
  &__images {
    width: 20%;
  }
}

.s-productInfo {
  &__title {
    margin-bottom: 0.5rem;
    font-weight: 800;
    font-size: 16px;
  }
  &__list {
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    li {
      margin-bottom: 0.5rem;
      .icon {
        margin-right: 0.5rem;
        color: $primary;
      }
    }
  }
}

.s-product {
  &__text a {
    text-decoration: none;
    color: $black;
    &:hover {
      color: $gray-600;
    }
  }
  &__strong {
    margin-left: 0.25rem;
    font-weight: bold;
  }

  &__btn {
    font-size: 16px;
    align-items: center;

    .icon {
      font-size: 18px;
    }

    &.btn-lg {
      width: 38px;
      height: 38px;
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__description {
    p:last-child {
      margin-bottom: 0;
    }
  }

  // Tags
  &__tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .icon {
      -ms-flex-item-align: center;
      align-self: center;
      margin-right: 7px;
      font-size: 20px;
      color: $primary;
    }
  }
  &__tag {
    display: block;
    margin-left: 5px;
    font-weight: 500;
    font-size: 13px;
    color: $gray-900;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}

.s-price {
  font-size: 21px;
  line-height: 1;
  &__old {
    font-size: 18px;
    margin-left: 1rem;
  }
  &__price {
    display: block;
    margin-left: 1rem;
    font-weight: bold;
    color: $success;
  }
}

#product {
  .img-thumbnail {
    height: 50px;
  }
}
